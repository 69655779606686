<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          style="width: 140px"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
          clearable
          v-model="taskName"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">教育机构</label>
        <el-input
          style="width: 160px"
          placeholder="请输入教育机构"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchTitle"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集类型</label>
        <el-select
          size="small"
          clearable
          v-model="searchCollectType"
          placeholder="全部"
          style="width: 120px"
        >
          <el-option
            v-for="item in CollectTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          clearable
          style="width: 140px"
          type="date"
          placeholder="开始时间"
          autocomplete="off"
          size="small"
          v-model="searchBeginCollect"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          type="date"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 140px"
          v-model="searchEndCollect"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">上传时间</label>
        <el-date-picker
          clearable
          style="width: 140px"
          type="date"
          placeholder="开始时间"
          autocomplete="off"
          size="small"
          v-model="searchBeginBatch"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          type="date"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 140px"
          v-model="searchEndBatch"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">票据号</label>
        <el-input
          style="width: 130px"
          placeholder="请输入票据号"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchBillNo"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-input
          style="width: 130px"
          placeholder="请输入摄影师"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchPhotographer"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集人数</label>
        <el-input
          style="width: 120px"
          placeholder="最小人数"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchMinNumber"
        ></el-input>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-input
          style="width: 120px"
          placeholder="最大人数"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchMaxNumber"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">确认到款状态</label>
        <el-select
          size="small"
          clearable
          v-model="searchIsCollectione"
          placeholder="全部"
          style="width: 120px"
        >
          <el-option
            v-for="item in IsCollectioneList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">联系人</label>
        <el-input
          style="width: 140px"
          placeholder="请输入联系人"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchContact"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">批次名称</label>
        <el-input
          style="width: 140px"
          placeholder="请输入批次名称"
          autocomplete="off"
          size="small"
          clearable
          v-model="batchName"
        ></el-input>
      </div>

      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
        >
          查询
        </el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="handleDownload"
          :loading="DownloadLoading"
          size="small"
        >
          导出
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: auto">
      <el-table
        :data="ticketList"
        v-loading="ticketLoading"
        stripe
        border
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @sort-change="sortChange"
      >
        <template #empty>
          <p>{{ ticketLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="title" label="任务名称" width="280" fixed>
        </el-table-column>
        <el-table-column
          prop="schoolName"
          label="教育机构"
          width="200"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="schoolCode"
          label="院校代码"
          width="80"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column prop="contact" label="联系人" width="100" align="center" fixed>
          <template slot-scope="scope">
            <span>{{ scope.row.contact }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="contactPhone"
          label="联系电话"
          width="120"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contactPhone }}</span></template
          >
        </el-table-column>

        <el-table-column
          prop="totalCount"
          label="采集人数"
          width="80"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="shotPrice"
          label="收费标准"
          width="120"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <span>{{ Number(scope.row.shotPrice).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="accountsPayable"
          label="应交款"
          width="100"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <span>{{ Number(scope.row.accountsPayable).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="photographer"
          label="摄影师"
          width="100"
          fixed
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          :formatter="columnDateFormat"
          label="采集时间"
          width="120"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="batchTime"
          :formatter="columnDateFormat"
          label="上传时间"
          width="120"
          align="center"
          fixed
          sortable="custom"
        >
        </el-table-column>

        <el-table-column
          prop="collectionTime"
          :formatter="columnDateFormat"
          label="交款时间"
          width="120"
          align="center"
          sortable="custom"
          fixed
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.collectionTime">未收款</span>
            <span v-else>{{ formDateFormat(scope.row.collectionTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="billTime"
          :formatter="columnDateFormat"
          label="财务入账时间"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.billTime">暂无入账</span>
            <span v-else>{{ formDateFormat(scope.row.billTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="batchReceipt"
          label="票据号"
          width="100"
          align="center"
          fixed
          sortable="custom"
        >
          <template slot-scope="scope">
            <div v-for="item in scope.row.batchReceipt" :key="item.billNo">
              <span>{{ item.billNo }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="batchName" label="采集批次" width="200" align="center">
        </el-table-column>
        <el-table-column
          prop="generateTime"
          label="生成时间"
          :formatter="columnDateFormat"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="contactAddress" label="地址" width="280" align="center">
        </el-table-column>
        <el-table-column prop="postalCode" label="邮编" width="120" align="center">
        </el-table-column>
        <el-table-column prop="shotSubject" label="采集对象" width="120" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.shotSubject == 0">新生</span>
            <span v-show="scope.row.shotSubject == 1">毕业生</span></template
          >
        </el-table-column>
        <el-table-column
          prop="graduationYear"
          label="毕业年份"
          width="160"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="educationType" label="学历层次" width="150" align="center">
        </el-table-column>

        <el-table-column prop="collectType" label="采集类型" width="100" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.collectType == 0">校拍</span>
            <span v-show="scope.row.collectType == 1">散拍</span>
            <span v-if="scope.row.collectType == 2">
              <span v-show="scope.row.realCollectType == 0 || 2">校拍</span>
              <span v-show="scope.row.realCollectType == 1">散拍</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="mailNumber" label="邮寄数" width="150" align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.mailNumber"></span>
          </template>
        </el-table-column>
        <el-table-column prop="centerNo" label="拍摄卡号" width="180" align="center">
        </el-table-column>
        <el-table-column prop="isCollection" label="到款状态" width="160" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.isCollection == 0" style="color: #ff6d52"
              >未确认到款</span
            >
            <span v-if="scope.row.isCollection == 1" style="color: #0bc58d"
              >已确认到款</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="batchName" label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="handleSchoolButton"
              @click="handleSchool(scope.row)"
              ><span v-if="scope.row.informationStatus == 0"> 填写学校信息</span>
              <span v-if="scope.row.informationStatus == 1"> 修改学校信息</span>
            </el-button>
            <el-button
              type="text"
              v-if="handleBillNButton"
              @click="handleBillNo(scope.row)"
            >
              <span
                v-if="
                  scope.row.billStatus == 0 &&
                  scope.row.isCollection == 0 &&
                  scope.row.informationStatus == 1
                "
              >
                填写票据号</span
              >

              <span
                v-if="scope.row.batchReceipt !== null && scope.row.informationStatus == 1"
              >
                修改票据号</span
              >
            </el-button>
            <el-button
              type="text"
              @click="handleCollection(scope.row)"
              v-show="scope.row.isCollection == 0 && scope.row.batchReceipt"
              v-if="CollectionButton"
            >
              确认已收款
            </el-button>
            <el-button type="text" v-if="viewInfoButton" @click="handleInfo(scope.row)">
              查看明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加票据 -->
    <div>
      <el-dialog
        :title="BillNoTitle"
        :visible.sync="BillNoVisibleview"
        width="400px"
        center="center"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <!-- 散拍 -->
        <el-form ref="billForm" label-width="80px" :model="billForm" :rules="billRules">
          <el-form-item label="票据号" name="billNo" prop="billNo">
            <el-input
              v-model="billForm.billNo"
              style="width: 280px"
              type="text"
              placeholder="请输入票据号"
            ></el-input>
          </el-form-item>

          <el-form-item label="交款时间" name="billTime" prop="billTime">
            <el-date-picker
              v-model="billForm.billTime"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择交款时间"
              autocomplete="off"
              style="width: 280px"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button
            type="primary"
            @click="handleConfirm"
            style="margin-left: 20px"
            :loading="ConfirmLoading"
            >保 存
          </el-button>
        </div>
      </el-dialog>
      <!-- 学校信息 -->
      <el-dialog
        :title="schoolTitle"
        :visible.sync="schoolVisible"
        width="900px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          ref="schoolData"
          :model="schoolData"
          label-width="150px"
          :rules="schoolRules"
        >
          <el-form-item label="学校" prop="schoolId">
            <el-select
              style="width: 600px"
              filterable
              v-model="schoolData.schoolId"
              remote
              reserve-keyword
              placeholder="请输入学校名或代码检索"
              :remote-method="remoteMethod"
              :loading="loading"
              autocomplete="off"
              @change="selectSchool"
            >
              <el-option
                v-for="item in schools"
                :key="item.id"
                :label="item.schoolFilterName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学校代码" prop="schoolCode">
            <el-input
              style="width: 600px"
              disabled
              type="text"
              v-model="schoolData.schoolCode"
              placeholder="请输入学校编码"
            ></el-input>
          </el-form-item>

          <el-form-item label="联系人" prop="contactName">
            <el-input
              style="width: 600px"
              type="text"
              v-model="schoolData.contactName"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input
              style="width: 600px"
              type="text"
              v-model.trim="schoolData.contactPhone"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="contactAddress">
            <el-input
              style="width: 600px"
              type="text"
              v-model="schoolData.contactAddress"
              placeholder="请输入地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮政编码" prop="postalCode">
            <el-input
              style="width: 600px"
              type="text"
              v-model="schoolData.postalCode"
              placeholder="请输入邮政编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="contactEmail">
            <el-input
              style="width: 600px"
              type="text"
              v-model="schoolData.contactEmail"
              placeholder="请输入电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="教育机构" prop="educationalType">
            <el-input
              style="width: 600px"
              type="text"
              v-model="schoolData.educationalType"
              placeholder="请输入教育机构"
            ></el-input>
          </el-form-item>
          <el-form-item label="教育层次" prop="schoolTyp">
            <el-select
              @change="$forceUpdate()"
              style="width: 600px"
              clearable
              v-model="schoolData.schoolTyp"
              placeholder="教育层次"
              autocomplete="off"
              multiple
            >
              <el-option
                v-for="item in educationList"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否加急" prop="isWorry">
            <el-radio-group v-model="schoolData.isWorry">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              style="width: 80%"
              v-model="schoolData.remarks"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCanceSchooll">取 消</el-button>
          <el-button
            type="primary"
            @click="handleConfirmSchool"
            style="margin-left: 20px"
            :loading="SchoolLoading"
            >保 存
          </el-button>
        </div>
      </el-dialog>
      <!-- 查看明细弹窗 -->
      <div style="height: 100%">
        <el-dialog
          title="查看明细"
          :visible.sync="InfoVisibleview"
          width="80%"
          class="ERER"
        >
          <!-- 搜索部分 -->
          <div style="display: flex; flex-wrap: wrap">
            <div class="condition-item">
              <label class="fn-14">拍照序号</label>
              <el-input
                style="width: 120px"
                placeholder="请输入拍照序号"
                autocomplete="off"
                size="small"
                v-model="infosearch.searchBatchIndex"
                clearable
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">姓名</label>
              <el-input
                style="width: 120px"
                placeholder="请输入姓名"
                autocomplete="off"
                size="small"
                clearable
                v-model="infosearch.searchContact"
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">身份证号</label>
              <el-input
                style="width: 180px"
                placeholder="请输入身份证号"
                autocomplete="off"
                size="small"
                clearable
                v-model="infosearch.searchIdNumber"
              ></el-input>
            </div>
            <div class="condition-item">
              <el-button
                type="primary"
                size="small"
                style="width: 85px"
                @click="handleSearchInfo"
                >查询</el-button
              >
            </div>
          </div>

          <!-- 弹窗列表盒子 -->
          <div>
            <el-table
              border
              :data="InfoList"
              style="width: 100%; text-align: center"
              height="400"
              :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
              v-loading="tableInfoLoading"
            >
              <template #empty>
                <p>
                  {{ tableInfoLoading == true ? "数据加载中" : "暂无数据" }}
                </p>
              </template>

              <el-table-column prop="batchIndex" label="拍照序号" width="120" fixed>
              </el-table-column>
              <el-table-column align="center" prop="name" label="姓名" width="120">
                <template slot-scope="scope">
                  <span @click="PhotoView(scope.row)" style="cursor: pointer">
                    <i class="el-icon-picture"></i
                    >{{ scope.row.name }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="idNumber"
                label="身份证号"
                width="240"
              >
                <template slot-scope="scope">
                  <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
                >
              </el-table-column>
              <el-table-column align="center" prop="studentCode" label="学号" width="160">
              </el-table-column>
              <el-table-column
                align="center"
                prop="schoolType"
                label="学校类型"
                width="160"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="educationType"
                label="学历类型"
                width="160"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="schoolCode"
                label="学校代码"
                width="160"
              >
              </el-table-column>
              <el-table-column align="center" prop="schoolName" label="学校" width="180">
              </el-table-column>
              <el-table-column align="center" prop="major" label="专业" width="180">
              </el-table-column>
              <el-table-column align="center" prop="cjm" label="采集码" width="180">
              </el-table-column>
            </el-table>
          </div>
          <div style="display: flex; justify-content: center">
            <el-pagination
              @size-change="handleSizeChangeInfo"
              @current-change="handleCurrentChangeInfo"
              :current-page="currentPageInfo"
              style="display: flex; justify-content: center; flex-direction: row"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              :page-size="pageSizeInfo"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalInfo"
            >
            </el-pagination>
          </div>
        </el-dialog>
      </div>
      <!-- 查看照片 -->
      <el-dialog
        title="学生照片"
        :visible.sync="PhotoVisibleview"
        width="50%"
        class="ERER"
      >
        <div style="text-align: center">
          <el-image :src="photoImg"></el-image>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryAllTask,
  confirmReceipt,
  batchReceiptAdd,
  schoolCollectsInformation,
} from "@/api/ticket";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { getSchoolFiller, getSchool } from "@/api/school";
import { queryInfoByTask } from "@/api/batch";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

export default {
  name: "Collection",
  data() {
    // const restest = /^\d+$|^\d+[.]?\d+$/;
    const telres = /^1[0-9]{10}$/;

    const validateBillNo = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入票据号"));
      } else {
        callback();
      }
    };
    const validatebillTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择交款时间"));
      } else {
        callback();
      }
    };
    const validateEducationalType = (rule, value, callback) => {
      if (!value) {
        callback(new Error("教育层次不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校信息不能为空"));
      } else {
        callback();
      }
    };
    const validateContactPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的11位数"));
      } else {
        if (telres.test(value) == false) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    const validateIsWorry = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否加急"));
      } else {
        callback();
      }
    };
    return {
      // 列表
      ticketList: [],
      ticketLoading: false,
      BillNoVisibleview: false,
      BillNoTitle: "",
      // 搜索条件
      searchTitle: "",
      searchCollectType: null,
      searchBeginCollect: null,
      searchEndCollect: null,
      searchBeginBatch: null,
      searchEndBatch: null,
      searchBillNo: "",
      searchPhotographer: "",
      searchMinNumber: "",
      searchMaxNumber: "",
      searchIsCollectione: null,
      searchContact: "",
      batchName: "",
      taskName: "",
      CollectTypeList: [
        {
          label: "校拍",
          value: "0",
        },
        {
          label: "散拍",
          value: "1",
        },
      ],
      IsCollectioneList: [
        {
          label: "未收",
          value: 0,
        },
        {
          label: "已收",
          value: 1,
        },
      ],
      billForm: {},
      billRules: {
        billNo: [
          {
            required: true,
            trigger: "blur",
            validator: validateBillNo,
          },
        ],
        billTime: [
          {
            required: true,
            trigger: "blur",
            validator: validatebillTime,
          },
        ],
      },
      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: 0,

      DownloadLoading: false,
      ConfirmLoading: false,
      isCollection: null,
      batchReceipt: null,
      // 学校信息
      schoolVisible: false,
      schoolData: {},
      schoolRules: {
        schoolId: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolId,
          },
        ],

        contactName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        contactPhone: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactPhone,
          },
        ],
        contactAddress: [{ required: true, message: "请输入地址", trigger: "blur" }],
        postalCode: [{ required: true, message: "请输入编码", trigger: "blur" }],
        // contactEmail: [
        //   { required: true, message: "请输入电子邮箱", trigger: "blur" },
        // ],

        schoolTyp: [
          {
            required: true,
            trigger: "blur",
            validator: validateEducationalType,
          },
        ],
        educationalType: [{ required: true, message: "请输入教育机构", trigger: "blur" }],
        isWorry: [
          {
            required: true,
            trigger: "blur",
            validator: validateIsWorry,
          },
        ],
      },
      searchSchoolId: null,
      // schoolCode: null,
      SchoolLoading: false,
      schools: [],
      taskId: null,
      value: [],
      allSchools: [],
      schoolTitle: null,
      schoolCollectType: null,
      informationStatus: null,
      informationStatusId: null,

      educationList: [
        {
          label: "普通",
        },
        {
          label: "成人 ",
        },
        {
          label: "研究生",
        },
        {
          label: "MBA",
        },
        {
          label: "留学生",
        },
      ],

      // 查看明细
      InfoList: [],
      tableInfoLoading: false,
      InfoVisibleview: false,
      // 明细搜索
      infosearch: {
        searchContact: "",
        searchIdNumber: null,
        searchBatchIndex: null,
      },
      InfoBatchId: null,
      InfoCollectType: null,
      currentPageInfo: 1,
      pageSizeInfo: 20,
      totalInfo: 0,
      PhotoVisibleview: false,
      photoImg: null,

      // 排序
      isBatchTime: null,
      isCollectionTime: null,
      isBillNo: null,

      // 权限按钮
      searchButton: false,
      handleSchoolButton: false,
      handleBillNButton: false,
      CollectionButton: false,
      viewInfoButton: false,
    };
  },
  created() {
    this.query();
    this.querySchools();
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 132;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "handleSchoolButton") {
            this.handleSchoolButton = true;
          }
          if (button.code === "handleBillNButton") {
            this.handleBillNButton = true;
          }
          if (button.code === "CollectionButton") {
            this.CollectionButton = true;
          }
          if (button.code === "viewInfoButton") {
            this.viewInfoButton = true;
          }
          if (button.code === "CollectionButton") {
            this.CollectionButton = true;
          }
        }
      });
    },
    query() {
      const data = {
        title: this.searchTitle,
        collectType: this.searchCollectType,
        beginCollect: this.formDateFormat(this.searchBeginCollect),
        endCollect: this.formDateFormat(this.searchEndCollect),
        beginBatch: this.formDateFormat(this.searchBeginBatch),
        endBatch: this.formDateFormat(this.searchEndBatch),
        billNo: this.searchBillNo,
        photographer: this.searchPhotographer,
        minNumber: this.searchMinNumber,
        maxNumber: this.searchMaxNumber,
        isCollection: this.searchIsCollectione,
        contact: this.searchContact,
        isBatchTime: this.isBatchTime,
        isCollectionTime: this.isCollectionTime,
        isBillNo: this.isBillNo,
        batchName: this.batchName,
        taskName: this.taskName,
      };
      this.ticketLoading = true;
      queryAllTask(data, this.currentPage, this.pageSize).then((resp) => {
        this.ticketList = resp.data.content;
        this.total = resp.data.totalElements;
        this.ticketLoading = false;
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    // 排序规则
    sortChange(custom) {
      this.isBatchTime = null;
      this.isCollectionTime = null;
      this.isBillNo = null;
      if (custom.prop == "batchTime") {
        if (custom.order == "ascending") {
          this.isBatchTime = "1";
        } else if (custom.order == "descending") {
          this.isBatchTime = "2";
        }
      } else if (custom.prop == "collectionTime") {
        if (custom.order == "ascending") {
          this.isCollectionTime = "1";
        } else if (custom.order == "descending") {
          this.isCollectionTime = "2";
        }
      } else if (custom.prop == "batchReceipt") {
        if (custom.order == "ascending") {
          this.isBillNo = "1";
        } else if (custom.order == "descending") {
          this.isBillNo = "2";
        }
      }
      this.query();
    },
    // 添加票据号
    handleBillNo(data) {
      this.BillNoVisibleview = true;
      this.isCollection = data.isCollection;
      this.batchReceipt = data.batchReceipt;
      this.billForm = {};
      if (data.billStatus == 0) {
        this.billForm.id = data.id;
        this.billForm.status = 0;
        this.billForm.collectType = data.collectType;
        this.BillNoTitle = "填写票据号";
      } else {
        this.BillNoTitle = "修改票据号";
        let JSON = data.batchReceipt;
        JSON.map((item) => {
          this.billForm = {
            billNo: item.billNo,
            billTime: item.billTime,
            id: data.id,
            collectType: data.collectType,
            status: 1,
          };
        });
      }
    },
    // 添加学校信息
    handleSchool(data) {
      // console.log(data);
      // this.schoolData={}
      this.informationStatus = data.informationStatus;
      this.taskId = data.id;
      this.schoolCollectType = data.collectType;
      if (data.informationStatus == 0) {
        this.schoolTitle = "填写学校信息";
        this.schoolData = {};
        // this.schoolCode = null;
      } else {
        this.schoolTitle = "修改学校信息";
        this.schoolData = data.schoolCollectsInformation;
        // this.schoolCode = data.schoolCollectsInformation.schoolCode;
        this.informationStatusId = data.schoolCollectsInformation.id;
        // console.log(data.schoolCollectsInformation);
        // if (this.schoolData.collectType == 1) {
        this.schoolData.schoolId = this.schoolData.schoolName;
        // }
        this.schoolData.schoolTyp = data.schoolCollectsInformation.schoolType
          .split(",")
          .map(String);
      }
      this.schoolVisible = true;
    },
    // 学校信息保存
    handleConfirmSchool() {
      this.$refs.schoolData.validate((valid) => {
        if (valid) {
          let json = JSON.parse(JSON.stringify(this.schoolData));
          let postdata = json;
          postdata.taskId = this.taskId;
          // postdata.schoolCode = this.schoolCode;
          postdata.schoolType = json.schoolTyp.join(",");
          postdata.collectType = this.schoolCollectType;
          if (this.informationStatus == 1) {
            postdata.id = this.informationStatusId;
          }
          if (this.schoolCollectType == 1) {
            postdata.schoolCode = "";
          }
          this.SchoolLoading = true;
          schoolCollectsInformation(postdata)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify.success({
                  title: "成功",
                  message: resp.message,
                });
              } else {
                this.$notify({
                  title: "失败",
                  message: resp.message,
                  type: "warning",
                });
                this.SchoolLoading = false;
              }
              this.SchoolLoading = false;
              this.schoolVisible = false;
              this.query();
            })
            .catch(() => {
              this.SchoolLoading = false;
              this.schoolVisible = false;
              this.query();
            });
        }
      });
    },
    // 学校取消
    handleCanceSchooll() {
      this.schoolData = {};
      this.schoolVisible = false;
    },
    //选择学校的时候带出学校联系人和联系电话
    selectSchool(schoolId) {
      getSchool(schoolId).then((resp) => {
        this.schoolData.schoolCode = resp.data.schoolCode;
        this.schoolData.schoolName = resp.data.schoolName;
      });
    }, // 确认已收款
    handleCollection(data) {
      this.$confirm("是否确认收款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const postData = { id: data.id, collectType: data.collectType };
          confirmReceipt(postData)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "温馨提示",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.data.msg,
                  type: "info",
                });
              }
              this.currentPage = 1;
              this.query();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 票据保存
    handleConfirm() {
      this.$refs.billForm.validate((valid) => {
        if (valid) {
          let json = JSON.parse(JSON.stringify(this.billForm));
          let postdata = json;
          // postdata.billNo = json.billNo;
          // postdata.billTime = json.billTime;
          this.ConfirmLoading = true;
          batchReceiptAdd(postdata).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "成功",
                message: resp.message,
              });
            } else {
              this.$notify({
                title: "失败",
                message: resp.message,
                type: "warning",
              });
            }
            this.query();
            this.ConfirmLoading = false;
            this.BillNoVisibleview = false;
          });
        } else {
          this.ConfirmLoading = false;
          this.BillNoVisibleview = false;
          return false;
        }
      });
    },

    // 票据取消
    handleCancel() {
      this.billForm = {};
      this.BillNoVisibleview = false;
    },
    // 导出
    handleDownload() {
      this.DownloadLoading = true;
      axios({
        method: "GET",
        url: "/api/csias/sys/batchReceipt/exportAll",
        params: {
          title: this.searchTitle,
          collectType: this.searchCollectType,
          beginCollect: this.searchBeginCollect,
          endCollect: this.searchEndCollect,
          beginBatch: this.searchBeginBatch,
          endBatch: this.searchEndBatch,
          billNo: this.searchBillNo,
          photographer: this.searchPhotographer,
          minNumber: this.searchMinNumber,
          maxNumber: this.searchMaxNumber,
          isCollection: this.searchIsCollectione,
        },
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.DownloadLoading = false;
        },
        () => {
          this.DownloadLoading = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    // 查看明细
    handleInfo(data) {
      this.InfoVisibleview = true;
      this.InfoVisibleview = true;
      this.InfoBatchId = data.id;
      this.InfoCollectType = data.collectType;
      this.InfoList = [];
      this.infosearch = {};
      let postData = {
        id: data.id,
        title: data.title,
        collectType: data.collectType,
      };
      this.InfobatchlList(postData);
    },
    InfobatchlList(data) {
      let postData = {
        id: data.id,
        title: data.title,
        collectType: data.collectType,
        batchIndex: this.infosearch.searchBatchIndex,
        name: this.infosearch.searchContact,
        idNumber: this.infosearch.searchIdNumber,
      };
      this.tableInfoLoading = true;
      queryInfoByTask(postData, this.currentPageInfo, this.pageSizeInfo).then((resp) => {
        if (resp.code == 0) {
          let dataview = resp.data.content;
          this.InfoList = dataview;
          this.totalInfo = resp.data.totalElements;
        }
        this.tableInfoLoading = false;
      });
    },
    // 查看照片
    PhotoView(data) {
      // pathCollectedPhoto
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        data.pathCollectedPhoto +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            this.PhotoVisibleview = true;
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
          }
        },
        () => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    // 查看明细列表分页
    handleSearchInfo() {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.currentPageInfo = 1;
      this.InfobatchlList(postData);
    },
    handleSizeChangeInfo(val) {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.pageSizeInfo = val;
      this.InfobatchlList(postData);
    },
    handleCurrentChangeInfo(val) {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.currentPageInfo = val;
      this.InfobatchlList(postData);
    },
    columnDateFormat(row, column) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null || "") {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style></style>
